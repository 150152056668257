<template lang='pug'>
    #KnowledgeArticles.main-page.page-noScroll.pb-0.position-relative
        v-row#page-head.d-flex.align-center.justify-md-space-between
            v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='6' )
                h1.my-0 {{$t('KNOWLEDGE_ARTICLE.TITLE')}}
                v-btn.ml-3.edit-deal-btn(@click='showAddKA()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
                    v-icon(size='16') icon-add
            v-col(cols='12' md='6' )
                v-row.justify-md-end(no-gutters='no-gutters')
                    v-col(cols='auto').ml-4.d-flex
                        .btn-group
                            v-text-field(v-model='search' flat dense solo prepend-inner-icon='icon-search')
                        importExportBtn(:page='"KnowledgeArticle"' :selectStageType='null')
        #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
            .data-area
                v-data-table.h-100.deals-table.ellipsis(:headers='KAHeader' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" :items='articleList' :items-per-page='itemPerPage' :page.sync='page' :page-count='pageCount' :sort-desc.sync='sortByDesc' :sort-by.sync="sortBy" multi-sort height='100%' hide-default-footer='hide-default-footer' item-key='id' :loading='isLoading' loading-text="Loading..." )
                    template(v-slot:item.title='{ item }')
                        v-btn.w-100.d-block(:href='"KnowledgeArticle/" + item.id' color='primary' text :title="item.title") 
                            .ellipsis.pt-2.w-100  
                                span.w-100  {{ item.title }}
                    template(v-slot:item.ka_number='{ item }')
                        .ellipsis
                            span {{ item.ka_number }}
                    template(v-slot:item.statusName='{ item }')
                            .ellipsis
                                span  {{ item.statusName }}
                    template(v-slot:item.updated_on='{ item }')
                        .ellipsis
                            span {{ item.updated_on }}
                    template(v-slot:item.updated_on='{ item }')
                            .ellipsis
                                span {{ getLittleFormatTime(item.updated_on) }}
                    template(v-slot:item.owner.fullname='{ item }')
                        .d-flex.justify-start.align-center
                            v-list-item-avatar.ma-0.mx-2.d-none.d-lg-inline-block(color='aliceBlue' size='40')
                                v-icon(v-if="item.owner == null") mdi-account
                                v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                                img(v-else :src='item.owner.usersetting.photo')
                            a(v-if="item.owner != null" :href='onMail(item.owner.email)' :title="item.owner.fullname" class='ellipsis mx-4') {{ item.owner.fullname }}

                    template(v-slot:item.action='{ item, index }')
                        v-btn(icon='icon' @click='onDelete(item.id, index)')
                            v-icon(size='18') icon-remove
                label.text-right.mr-4 {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
            success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
        v-dialog(v-model='exportDialog' max-width='500' v-if='exportDialog')
            export-dialog(@emitExport='onExport' page_name='KnowledgeArticle')
</template>

<script>
// Dialog
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import exportDialog from '@/components/Common/exportDialog';
// service
import KnowledgeArticleDataService from "@/services/KnowledgeArticleDataService";
// List
import kaStatusList from "@/array/the_kaStatusList";
import i18n from '/common/plugins/vue-i18n.js' 
// other 
import importExportBtn from "@/components/Common/importExportBtn";

export default {
  components: {
    exportDialog,
    messageDialog,
    successDialog,
    errorDialog,
    importExportBtn
  },
  data() {
    return {
      // table setting
      awaitingSearch: false, 
      sortBy:[],
      sortByForSearch:[],
      sortByDesc:[],
      pageDataCount:null,
      page: 1,
      pageCount: 0,
      currentData:0,
      itemPerPage: this.getItemPerPage(),
      selectedRows: [],
      isLoading: true,
      KAHeader: [
        {
          text: i18n.t('KNOWLEDGE_ARTICLE.H_TITLE'),
          value: "title",
          align: "center"
        },
        {
          text: i18n.t('KNOWLEDGE_ARTICLE.NUMBER'),
          value: "ka_number",
          align: "center"
        },
        {
          text: i18n.t('KNOWLEDGE_ARTICLE.STATUS'),
          value: "statusName",
          align: "center"
        },
        {
          text: i18n.t('KNOWLEDGE_ARTICLE.UPDATED_ON'),
          value: "updated_on",
          align: "center",
          filterable: false,
        },
        {
          text: i18n.t('KNOWLEDGE_ARTICLE.AUTHOR'),
          value: "owner.fullname",
          align: "center"
        },
        {
          text: i18n.t('KNOWLEDGE_ARTICLE.ACTIVE'),
          value: "action",
          align: "center",
          width: '150px',
          sortable: false,
        },
      ],
      // Dialog
      exportDialog:false,
      messageDialog: false,
      message: "",
      successDialog: false,
      successMessage: "",
      errorDialog: false,
      errorMessage: "",
      // Data
      articleList: [],
      search: "",
      delId: "",
      succMode: "",
      // list
      kaStatusList: kaStatusList,
    };
  },
  async created() {
    this.getListData()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    async getListData(){
      await (this.isLoading = true);
      await window.addEventListener('resize', this.onResize)
      this.onResize();
      await KnowledgeArticleDataService.list(this.page,this.search,this.itemPerPage,this.sortByForSearch)
      .then(response => {
        this.articleList = response.data.list;
        this.pageCount = response.data.total_page
        this.pageDataCount = response.data.pageDataCount
        this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage
        this.articleList.forEach(article => {
          article.owner.fullname = article.owner.first_name != "" ? article.owner.last_name + " " +article.owner.first_name : article.owner.last_name;
          article.statusName = this.getItemNameFromList(this.kaStatusList, article.status);
        });
      })
      this.sortByForSearch = []
      await (this.isLoading = false);
    },
    getItemPerPage(){
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      return parseInt(windowH/objH);
    },
    onResize: function () {
      let windowH = window.innerHeight - 150; //table高度
      let objH = 70;
      this.itemPerPage = parseInt(windowH/objH);
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    // Dialog Area ----------------------------------------- [Start]
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if (this.succMode == "DELETE") {
          KnowledgeArticleDataService.delete(this.delId)
          .then(() => {
              this.showSuccessDialog();
              this.articleList.splice(this.delIndex, 1);
              this.currentData = this.currentData - 1
              this.pageDataCount = this.pageDataCount - 1
          })
          .catch(error => {
              this.showErrorDialog(error);
          });
        }
      }
    },
    // create article
    showAddKA() {
      this.$router.push('KnowledgeArticle/New');
    },
    // edit article
    onEditArticle(item) {
      this.$router.push(`KnowledgeArticle/${item.id}`);
    },
    onDelete(id, index) {
      this.succMode = "DELETE";
      this.delId = id;
      this.delIndex = index;
      this.showMessageDialog(i18n.t('KNOWLEDGE_ARTICLE.MESSAGE'));
    },
    getLittleFormatTime(data){
      var d = new Date(data);
      return d.toLocaleString("zh-TW");
    },
    exportCsv(){
      this.exportDialog = true      
    },
    onExport(){
      this.exportDialog = false
    },
    async importCsv() {
  },
  },
  watch:
  {
    "page":async function () {      
      await this.getListData()  
    },
    "search":async function () {      
      if (!this.awaitingSearch) {
                setTimeout(() => {
          this.page = 1
                    this.getListData();
                    this.awaitingSearch = false;					
                }, 1000);
                }
                this.awaitingSearch = true;
    },    
    "sortByDesc":async function () {  
      this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
      for(let i in this.sortByDesc)
      {
        if(this.sortByDesc[i] == true)
        {
          this.sortByForSearch[i] = '-'+this.sortByForSearch[i]          
        }        
      }
        await this.getListData()  
    },
  }
};
</script>